import React from "react";

import PageWrapper from "../components/PageWrapper";

const Privacy = () => {
  return (
    <>
      <PageWrapper>
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Política de Privacidad</h2>
                  <p className="font-size-7 mb-8">
                    POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS DE CARÁCTER
                    PERSONAL DEL SITIO
                  </p>
                  <p className="font-size-5 mb-0">Versión: 3.32</p>
                  <p className="font-size-5 mb-0">
                    Fecha de publicación: 26 de abril de 2023
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <div>
                      <h5 className="font-size-9 mb-7">
                        1. Contenido y conformidad
                      </h5>
                      <p className="font-size-7 heading-default-color">
                        La presente se configura como la Política de Privacidad
                        y Protección de datos de carácter personal (en adelante,
                        denominada “Política de Privacidad”) aplicable a los
                        datos de carácter personal facilitados a través del
                        sitio web https://timeview.io (en adelante, el “Sitio
                        Web”), habilitado principalmente para conocer y
                        gestionar los servicios ofrecidos por la empresa
                        DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                        (“DESIC”).
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Cualquier referencia a “Sitio Web” deberá igualmente
                        entenderse realizada al sitio web https://timeview.io o
                        a cualquiera de sus subdominios, dominios relacionados o
                        páginas web dependientes.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        “<strong>Usuario”</strong> es la persona que accede,
                        utiliza o navega a través del Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario decide libre y voluntariamente facilitar sus
                        datos de carácter personal a través del Sitio Web,
                        decidiendo libre y voluntariamente la utilización de los
                        servicios y vías de comunicación habilitadas en el Sitio
                        Web, en cuyo caso, se le podrá exigir la aportación de
                        determinados datos de carácter personal para poder dar
                        respuesta y trámite a su solicitud.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        La información legal completa del Sitio Web (en
                        adelante, la “
                        <strong>Información Legal Completa</strong>”) está
                        conformada con carácter conjunto, constituyendo una
                        unidad, por el texto contenido en el presente documento
                        de Información Legal (que incluye las Condiciones de
                        acceso, uso y navegación), así como por la Política de
                        Privacidad y por la Política de Cookies, documentos a
                        los que puede acceder directamente mediante enlace en la
                        parte inferior del Sitio Web (en adelante, todos los
                        documentos mencionados conjuntamente denominados como la
                        “Información Legal Completa”). En consecuencia, la
                        interpretación y consideración de la Información Legal
                        del Sitio Web ha de realizarse de forma conjunta por
                        todos los mencionados documentos.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El acceso, uso y navegación del Sitio Web por el
                        Usuario, son actos libres y voluntarios e implican su
                        consentimiento expreso y su plena conformidad con el
                        contenido íntegro de la denominada “Información Legal
                        Completa” (documento de Información Legal que incluye
                        Condiciones de acceso, uso y navegación, documento de
                        Política de Privacidad y documento de Política de
                        Cookies), y con el tratamiento de sus datos personales
                        conforme a la misma, por lo que si no está de acuerdo
                        con alguno de los términos dispuestos en estos
                        documentos, le rogamos no nos facilite datos de carácter
                        personal ni utilice el Sitio Web ni ninguno de los
                        servicios puestos a su disposición en el mismo.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario se obliga a la observancia y cumplimiento
                        riguroso de lo dispuesto en el contenido de los
                        mencionados documentos que forman parte de la
                        Información Legal Completa, así como de cualquier
                        disposición legal que fuera de aplicación.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        El Usuario puede imprimir y almacenar digitalmente todos
                        los documentos mencionados, encontrándose a su
                        disposición permanente mediante enlace de acceso directo
                        disponible en la parte inferior del Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Se recomienda al Usuario la lectura íntegra de la
                        Información Legal Completa (Información Legal, Política
                        de Privacidad y Política de Cookies), de forma regular,
                        incluso cada vez que acceda al Sitio Web y, en cualquier
                        caso, cada vez que decida facilitarnos datos de carácter
                        personal a través del Sitio Web, pues su contenido puede
                        estar sujeto a modificaciones. Cualquier modificación
                        será debidamente publicada y estará siempre accesible en
                        el Sitio Web, haciendo referencia a su versión y fecha.
                      </p>
                      <p className="font-size-7 heading-default-color mb-8">
                        DESIC adopta en el tratamiento de los datos los
                        principios dispuestos en el artículo 5 del RGPD:
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        • <strong>Licitud, lealtad y transparencia</strong>: Los
                        datos personales son tratados de manera lícita, leal y
                        transparente en relación con el interesado.
                        <br />
                        <br />• <strong>Limitación de la finalidad</strong>: Los
                        datos se recogen con fines determinados, explícitos y
                        legítimos, y no son tratados ulteriormente de manera
                        incompatible con dichos fines.
                        <br />
                        <br />• <strong>Minimización de datos</strong>: Los
                        datos son adecuados, pertinentes y limitados a lo
                        necesario en relación con los fines para los que son
                        tratados.
                        <br />
                        <br />• <strong>Exactitud</strong>: Los datos son
                        exactos, y si fuera necesario, actualizados. Se
                        adoptarán todas las medidas razonables para que se
                        supriman o rectifiquen sin dilación si los datos son
                        inexactos con respecto a los finales para los que se
                        tratan.
                        <br />
                        <br />•{" "}
                        <strong>Limitación del plazo de conservación</strong>:
                        Los datos son mantenidos de forma que se permita la
                        identificación de los interesados durante no más tiempo
                        del necesario para los fines del tratamiento de los
                        datos personales.
                        <br />
                        <br />• <strong>Integridad y confidencialidad</strong>:
                        Los datos son tratados de tal manera que se garantice
                        una seguridad adecuada mediante la aplicación de medidas
                        de control apropiadas.
                      </p>
                      <p className="font-size-7 heading-default-color mb-8">
                        Cuando se soliciten datos de carácter personal mediante
                        cumplimentación de un formulario o similar y sea preciso
                        hacer clic en un botón de “Enviar” o similar, se
                        encuentra a su disposición un cuadro con “Información
                        básica en protección de datos”, así como se encuentra
                        habilitada una casilla de Aceptación indicando que ha
                        leído y consentido, entre otros documentos, en su caso,
                        esta Política de Privacidad (y disponiendo de un enlace
                        directo a esta Política de Privacidad en ese momento),
                        de manera que el hacer clic en la mencionada casilla
                        implica necesariamente su manifestación de que ha leído
                        y ha sido informado del contenido de la Política de
                        privacidad y que otorga consentimiento a la misma y al
                        tratamiento de sus datos de carácter personal conforme a
                        lo dispuesto en su contenido.
                      </p>
                      <p className="font-size-7 heading-default-color mb-8">
                        Mediante la aceptación de la Política de Privacidad,
                        usted consiente expresamente a que los datos de carácter
                        personal facilitados a DESIC, incluyendo expresamente
                        sus datos de salud, en caso de facilitarlos
                        voluntariamente a través de esta vía, sean tratados por
                        DESIC con la/s finalidad/es mencionada/s y conforme al
                        contenido de la Política de Privacidad.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-9 mb-7">
                        2. Identificación del titular del dominio, prestador de
                        servicios, responsable del tratamiento de datos y medios
                        de contacto.
                      </h5>
                      <p className="font-size-7 heading-default-color mb-9">
                        En cumplimiento de lo dispuesto en la normativa
                        aplicable, se informa que el titular del dominio{" "}
                        <strong>https://timeview.io</strong> (el Sitio Web) y la
                        empresa que presta sus servicios y ofrece sus productos
                        a través del mismo, así como el responsable del
                        tratamiento de los datos facilitados es:
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <strong>
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC)
                        </strong>
                        , C.I.F. nº B-35492784, domicilio social y postal C/
                        Camelia, 15-17 C.P. 35010 Las Palmas de Gran Canaria
                        (Gran Canaria-España). Datos registrales: inscrita en el
                        Registro Mercantil de Las Palmas, al Tomo 1.357 General
                        de Sociedades, Folio 40, Hoja GC-18.504, inscripción 1ª.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Para establecer contacto directo</u> con DESARROLLO Y
                        SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC):
                        <br />
                        - Dirección postal/domicilio: C/ Camelia, 15-17 C.P.
                        35010 Las Palmas de Gran Canaria (Gran Canaria-España).
                        <br />- Teléfono: (+34) 928.37.40.26 / 928.37.40.28.
                        <br />- Correo electrónico:{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:desic@desic-sl.com"
                        >
                          desic@desic-sl.com
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>
                          Para ejercicio de derechos en protección de datos de
                          carácter personal
                        </u>
                        : <br />- Dirección postal/domicilio: C/ Camelia, 15-17
                        C.P. 35010 Las Palmas de Gran Canaria (Gran
                        Canaria-España). <br />- Teléfono: (+34) 928.37.40.26 /
                        928.37.40.28. <br />- Correo electrónico:
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:rgpd@desic-sl.com"
                        >
                          rgpd@desic-sl.com
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>
                          Para establecer contacto con el Delegado de Protección
                          de Datos
                        </u>
                        : <br />- Correo electrónico:{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:dpd@desic-sl.com"
                        >
                          dpd@desic-sl.com
                        </a>
                        .
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-9 mb-7">
                        3. Información básica y adicional sobre protección de
                        datos obtenidos en uso y gestión del Sitio Web
                      </h5>
                      <p className="font-size-7 heading-default-color">
                        En cumplimiento de lo dispuesto en el Reglamento (UE)
                        2016/679 del Parlamento Europeo y del Consejo, de 27 de
                        abril de 2016, relativo a la protección de las personas
                        físicas en lo que respecta al tratamiento de datos
                        personales y a la libre circulación de estos datos
                        ("Reglamento General de Protección de Datos", o "RGPD"),
                        en la Ley Orgánica 3/2018, de 5 de diciembre, de
                        Protección de datos personales y garantía de los
                        derechos digitales (“LOPDGDD”), y en la normativa
                        vigente en protección de datos, consintiendo al
                        tratamiento de sus datos, en caso de facilitarnos los
                        mismos mediante el uso y gestión del Sitio Web, le
                        informamos de que sus datos formarán parte de nuestro
                        sistema de tratamiento de datos, conforme a la siguiente
                        Información:
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Responsable del tratamiento</u>:{" "}
                        <strong>
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC)
                        </strong>
                        , C.I.F. nº B-35492784, domicilio social y postal en C/
                        Camelia, 15-17 C.P. 35010 Las Palmas de Gran Canaria
                        (Gran Canaria-España), teléfono de contacto: (+34)
                        928.37.40.26 / 928.37.40.28, correo electrónico de
                        contacto:{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:desic@desic-sl.com"
                        >
                          desic@desic-sl.com
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Sus datos proceden de los facilitados mediante la
                        cumplimentación de formulario de contacto/formulario de
                        solicitud de información y/o utilización de medios de
                        contacto habilitados en el Sitio Web.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        La aportación de sus datos es voluntaria, solicitándole
                        mediante su marcación con “*” los datos obligatorios y
                        necesarios para cumplir obligaciones legales y
                        circunstancias de su relación con la empresa, por lo que
                        no proporcionarlos podrá implicar no poder llevarlas a
                        cabo.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Cuando en los medios habilitados para recabar sus datos
                        de carácter personal los campos que recogen datos de
                        carácter personal estén marcados con un (*) serán de
                        cumplimentación obligatoria, siendo el resto, de
                        cumplimentación voluntaria. Si no se aportaran datos
                        considerados obligatorios, DESARROLLO Y SISTEMAS
                        INFORMÁTICOS CANARIAS, S.L. (DESIC) podrá no aceptar y/o
                        gestionar la consulta/solicitud formulada, o el servicio
                        web utilizado que se pretenda realizar.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        Cuando se soliciten datos de carácter personal mediante
                        cumplimentación de un formulario o similar y sea preciso
                        hacer clic en un botón de “Enviar” o similar, se
                        encuentra a su disposición un cuadro con “Información
                        básica en protección de datos”, así como se encuentra
                        habilitada una casilla de Aceptación indicando que ha
                        leído y consentido, entre otros documentos, en su caso,
                        a esta Política de Privacidad (y disponiendo de un
                        enlace directo a esta Política de Privacidad en ese
                        momento), de manera que el hacer clic en la mencionada
                        casilla implica necesariamente su manifestación de que
                        ha leído, ha consentido y ha sido informado del
                        contenido de esta Política de Privacidad, y que otorga
                        consentimiento a la misma y al tratamiento de sus datos
                        de carácter personal conforme a lo dispuesto en su
                        contenido.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Finalidad</u>: Gestionar, atender, dar respuesta y
                        mantener la relación generada a través de las páginas
                        web de la empresa, gestión y atención de usuarios y
                        potenciales clientes, así como conformar sus contenidos.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Base legal del tratamiento de sus datos</u>:
                        ejecución servicios solicitados, su consentimiento,
                        cumplimiento obligaciones legales aplicables, interés
                        legítimo en caso de personas físicas de contacto de
                        personas jurídicas (letras a), b) y c) del apartado 1
                        del artículo 6 del RGPD y art. 19 LOPDGDD).
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Destinatarios de sus datos</u>: responsable del
                        tratamiento y terceros de necesaria comunicación por
                        obligación legal o por existencia de su relación,
                        Administración pública con competencia en la materia, y
                        terceros con los que el responsable contrate prestación
                        de servicios (encargado de tratamiento) y sea necesario
                        para prestarlos.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        No existe intención del responsable del tratamiento de
                        realizar transferencia de sus datos a un tercer país u
                        organización internacional. Sin perjuicio de lo
                        anterior, DESIC usa los servicios de Google Ireland
                        Limited, para la comunicación por correo electrónico,
                        empresa igualmente ubicada en Estados Unidos,
                        consintiendo expresamente a esta posible cesión o
                        comunicación de datos a este país, el cual actualmente
                        no proporciona una protección adecuada en protección de
                        datos conforme a lo dispuesto por la Unión Europea tras
                        la invalidez del Escudo de Privacidad EU- EE.UU.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Plazo de conservación de datos</u>: coincidirá con la
                        duración de su relación con el responsable del
                        tratamiento y el necesario para cumplir con obligación
                        legal o formulación, ejercicio o defensa de
                        reclamaciones relacionadas con su relación o tratamiento
                        de sus datos, conservándose hasta finalizar plazos de
                        prescripción.{" "}
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Delegado en Protección de Datos</u>: puede ponerse en
                        contacto con nuestro Delegado en Protección de Datos a
                        través del correo electrónico
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="mailto:dpd@desic-sl.com"
                        >
                          dpd@desic-sl.com
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color">
                        <u>Sus derechos en protección de datos</u>: toda
                        información en el apartado 4 de esta Política de
                        Privacidad.
                      </p>
                      <p className="font-size-7 heading-default-color">
                        No se realizan decisiones automatizadas ni elaboración
                        de perfiles con sus datos por parte de DESIC.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-9 mb-7">
                        4. Derechos en protección de datos de carácter personal.
                      </h5>
                      <p className="font-size-7 heading-default-color">
                        Se le informa de la existencia de sus DERECHOS en
                        protección de datos, pudiendo solicitar los mismos al
                        responsable del tratamiento (DESIC), siendo los
                        siguientes:
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>DERECHO DE ACCESO</u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado (titular de los datos) tiene derecho a
                        obtener de DESIC (responsable del tratamiento)
                        confirmación de si se están tratando o no datos
                        personales que le conciernen y, en tal caso, derecho de
                        acceso a los datos personales y a la siguiente
                        información:
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        a) los fines del tratamiento;
                        <br />
                        <br />
                        b) las categorías de datos personales de que se trate;
                        <br />
                        <br />
                        c) los destinatarios o las categorías de destinatarios a
                        los que se comunicaron o serán comunicados los datos
                        personales, en particular destinatarios en terceros u
                        organizaciones internacionales;
                        <br />
                        <br />
                        d) de ser posible, el plazo previsto de conservación de
                        los datos personales o, de no ser posible, los criterios
                        utilizados para determinar este plazo;
                        <br />
                        <br />
                        e) la existencia del derecho a solicitar del responsable
                        la rectificación o supresión de datos personales o la
                        limitación del tratamiento de datos personales relativos
                        al interesado, o a oponerse a dicho tratamiento;
                        <br />
                        <br />
                        f) el derecho a presentar una reclamación ante una
                        autoridad de control;
                        <br />
                        <br />
                        g) cuando los datos personales no se hayan obtenido del
                        interesado, cualquier información disponible sobre su
                        origen;
                        <br />
                        <br />
                        h) la existencia de decisiones automatizadas, incluida
                        la elaboración de perfiles, y, al menos en tales casos,
                        información significativa sobre la lógica aplicada, así
                        como la importancia y las consecuencias previstas de
                        dicho tratamiento para el interesado.
                        <br />
                        <br />
                        i) cuando se transfieran datos personales a un tercer
                        país o a una organización internacional, el interesado
                        tendrá derecho a ser informado de las garantías
                        adecuadas en virtud del artículo 46 relativas a la
                        transferencia.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>DERECHO DE RECTIFICACIÓN</u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado (titular de los datos) tendrá derecho a
                        obtener sin dilación indebida de DESIC (responsable del
                        tratamiento) la rectificación de los datos personales
                        inexactos que le conciernan. Teniendo en cuenta los
                        fines del tratamiento, el interesado tendrá derecho a
                        que se completen los datos personales que sean
                        incompletos, inclusive mediante una declaración
                        adicional.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>DERECHO DE SUPRESIÓN</u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado (titular de los datos) tendrá derecho a
                        obtener sin dilación indebida de DESIC (responsable del
                        tratamiento) la supresión de los datos personales que le
                        conciernan, estando obligada DESIC a suprimirlos sin
                        dilación indebida los datos personales cuando concurra
                        alguna de las circunstancias siguientes:
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        a) los datos personales ya no sean necesarios en
                        relación con los fines para los que fueron recogidos o
                        tratados de otro modo;
                        <br />
                        <br />
                        b) el interesado retire el consentimiento en que se basa
                        el tratamiento, y este no se base en otro fundamento
                        jurídico;
                        <br />
                        <br />
                        c) el interesado se oponga al tratamiento y no
                        prevalezcan otros motivos legítimos para el tratamiento;
                        <br />
                        <br />
                        d) los datos personales hayan sido tratados
                        ilícitamente;
                        <br />
                        <br />
                        e) los datos personales deban suprimirse para el
                        cumplimiento de una obligación legal establecida en el
                        Derecho de la Unión o de los Estados miembros que se
                        aplique al responsable del tratamiento;
                        <br />
                        <br />
                        f) los datos personales se hayan obtenido en relación
                        con la oferta de servicios de la sociedad de la
                        información.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>
                            DERECHO A SOLICITAR LA LIMITACIÓN DEL TRATAMIENTO
                          </u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado (titular de los datos) tendrá derecho a
                        obtener de DESIC (responsable del tratamiento) la
                        limitación del tratamiento de los datos cuando se cumpla
                        alguna de las condiciones siguientes:
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        a) el interesado impugne la exactitud de los datos
                        personales, durante un plazo que permita al responsable
                        verificar la exactitud de los mismos;
                        <br />
                        <br />
                        b) el tratamiento sea ilícito y el interesado se oponga
                        a la supresión de los datos personales y solicite en su
                        lugar la limitación de su uso;
                        <br />
                        <br />
                        c) el responsable ya no necesite los datos personales
                        para los fines del tratamiento, pero el interesado los
                        necesite para la formulación, el ejercicio o la defensa
                        de reclamaciones;
                        <br />
                        <br />
                        d) el interesado se haya opuesto al tratamiento,
                        mientras se verifica si los motivos legítimos del
                        responsable prevalecen sobre los del interesado.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>DERECHO A LA PORTABILIDAD DE LOS DATOS</u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado (titular de los datos) tendrá derecho a
                        recibir los datos personales que le incumban, que haya
                        facilitado a DESIC (responsable del tratamiento), en un
                        formato estructurado, de uso común y lectura mecánica, y
                        a transmitirlos a otro responsable del tratamiento sin
                        que lo impida el responsable al que se los hubiera
                        facilitado, cuando:
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        a) el tratamiento esté basado en el consentimiento, y
                        <br />
                        <br />
                        b) el tratamiento se efectúe por medios automatizados.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado tendrá derecho a que los datos personales
                        se transmitan directamente de responsable a responsable
                        cuando sea técnicamente posible.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>
                            DERECHO DE OPOSICIÓN Y NO SER OBJETO DE DECISIONES
                            INDIVIDUALES AUTOMATIZADAS (incluida la elaboración
                            de perfiles)
                          </u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        El interesado (titular de los datos) tendrá derecho a
                        oponerse en cualquier momento, por motivos relacionados
                        con su situación particular, a que datos personales que
                        le conciernan sean objeto de tratamiento en determinados
                        supuestos, teniendo derecho además a no ser objeto de
                        una decisión basada únicamente en el tratamiento
                        automatizado, incluida la elaboración de perfiles, que
                        produzca efectos jurídicos en él o le afecte
                        significativamente de modo similar.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>DERECHO A RETIRAR SU CONSENTIMIENTO</u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        Se le informa de su derecho a retirar su consentimiento
                        en cualquier momento, sin que ello afecte a la licitud
                        del tratamiento basado en el consentimiento previo a su
                        retirada.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>
                            DERECHO A PRESENTAR UNA RECLAMACIÓN ANTE UNA
                            AUTORIDAD DE CONTROL
                          </u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        De igual manera, se le informa de su derecho a presentar
                        una reclamación ante una autoridad de control, siendo en
                        España, la Agencia Española de Protección de Datos{" "}
                        <a
                          className="text-blue-3 btn-link-with-underline ml-1"
                          href="https://www.aepd.es"
                          target="blank"
                          rel="noopener noreferrer"
                        >
                          www.aepd.es
                        </a>
                        .
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        -{" "}
                        <strong>
                          <u>
                            ¿CÓMO PUEDO EJERCITAR MIS DERECHOS EN PROTECCIÓN DE
                            DATOS?
                          </u>
                        </strong>
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        Puede ejercitar sus derechos en protección de datos de
                        carácter personal ante DESIC mediante cualquiera de las
                        siguientes dos vías:
                      </p>
                      <p className="font-size-7 heading-default-color pl-16">
                        1. Mediante escrito presentado en o dirigido al
                        domicilio de DESIC indicado en el apartado 2 de la
                        Política de Privacidad.
                        <br />
                        <br />
                        2. Mediante correo electrónico a la dirección facilitada
                        en el apartado 2 de la Política de Privacidad.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        Para ejercitar sus derechos deberá
                        entregarnos/enviarnos:
                      </p>
                      <p className="font-size-7 heading-default-color pl-16">
                        - Solicitud por escrito firmada.
                        <br />
                        <br />- Acreditación de su identidad (D.N.I., pasaporte
                        o documento identificativo equivalente).
                        <br />
                        <br />- Documentación que acredite la representación de
                        un tercero, en su caso (menor de 14 años, incapaz),
                        poder notarial o autorización de representación.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8 mt-8">
                        Dependiendo del derecho que ejercite, podremos
                        solicitarle información adicional que necesitemos para
                        localizar la información que nos solicita o para poder
                        satisfacer su derecho.
                      </p>
                      <p className="font-size-7 heading-default-color pl-8">
                        Si lo desea, ponemos a su disposición formularios para
                        el ejercicio de sus derechos, pudiendo solicitárnoslos
                        utilizando las mismas vías de ejercicio del apartado 2.
                      </p>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          5. Información básica y adicional sobre protección de
                          datos en ejercicio de derechos en protección de datos.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          En cumplimiento del RGPD o GDPR, la LOPDGDD y
                          normativa vigente en protección de datos, le
                          informamos y consiente a esta Información y al
                          tratamiento de sus datos conforme a la misma.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Responsable del tratamiento</u>:{" "}
                          <strong>
                            DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                            (DESIC)
                          </strong>
                          , C.I.F. nº B-35492784, domicilio social y postal en
                          C/ Camelia, 15-17 C.P. 35010 Las Palmas de Gran
                          Canaria (Gran Canaria-España), teléfono de contacto:
                          (+34) 928.37.40.26 / 928.37.40.28, correo electrónico
                          de contacto:{" "}
                          <a
                            className="text-blue-3 btn-link-with-underline ml-1"
                            href="mailto:desic@desic-sl.com"
                          >
                            desic@desic-sl.com
                          </a>
                          .
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Toda información o dato facilitado en ejercicio de sus
                          derechos en protección de datos, es incorporado a
                          nuestros sistemas de tratamiento, procediendo de los
                          facilitados en su solicitud de ejercicio, de
                          formularios habilitados al efecto, datos transmitidos,
                          facilitados u obtenidos por cualquier medio como
                          consecuencia de solicitud, contenido de correos
                          electrónicos con el responsable del tratamiento,
                          siendo los demás datos tratados los que se faciliten
                          libremente a lo largo de la comunicación entre ambas
                          partes.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Los datos obtenidos en el ejercicio de sus derechos en
                          protección de datos son los siguientes: nombre y
                          apellidos, D.N.I., pasaporte o documento
                          identificativo, domicilio, firma, medios de contacto y
                          acreditación de representación legal, en su caso
                          (datos obligatorios), pudiendo incluirse igualmente
                          por su parte, datos de salud, de manera voluntaria, a
                          lo que expresamente consiente.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Finalidad</u>: Gestionar el ejercicio de los
                          derechos en protección de datos de carácter personal y
                          las circunstancias que de los mismos y para los mismos
                          se deriven como necesarias.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Cuando en los medios para recabar sus datos los campos
                          tengan un (*) serán de cumplimentación obligatoria,
                          siendo el resto, voluntarios. Si no se aportaran datos
                          obligatorios podrá implicar no aceptar y/o gestionar
                          la consulta formulada, el servicio que se pretenda
                          utilizar.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Base legal del tratamiento de sus datos</u>: su
                          consentimiento y cumplir obligaciones legales
                          aplicables (letras a) y c) del apartado 1 del artículo
                          6 del RGPD).
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Destinatarios de sus datos</u>: responsable del
                          tratamiento y terceros de necesaria comunicación por
                          obligación legal o por existencia de su relación,
                          Administración pública con competencia en la materia,
                          entidades aseguradoras, Juzgados y Tribunales, y
                          terceros con los que el responsable contrate
                          prestación de servicios (encargado de tratamiento) y
                          sea necesario para prestarlos.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          No existe intención del responsable del tratamiento de
                          realizar transferencia de sus datos a un tercer país u
                          organización internacional. Sin perjuicio de lo
                          anterior, DESIC usa los servicios de Google Ireland
                          Limited, para la comunicación por correo electrónico,
                          empresa igualmente ubicada en Estados Unidos,
                          consintiendo expresamente a esta posible cesión o
                          comunicación de datos a este país, el cual actualmente
                          no proporciona una protección adecuada en protección
                          de datos conforme a lo dispuesto por la Unión Europea
                          tras la invalidez del Escudo de Privacidad EU- EE.UU.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Plazo de conservación de sus datos</u>: coincidirá
                          con la duración de su relación con el responsable del
                          tratamiento y el necesario para cumplir obligación
                          legal o formulación, ejercicio o defensa de
                          reclamaciones relacionadas con su relación o
                          tratamiento de datos, conservándose hasta finalizar
                          plazos de prescripción.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Delegado en Protección de Datos</u>: puede ponerse
                          en contacto con nuestro Delegado en Protección de
                          Datos a través del correo electrónico{" "}
                          <a
                            className="text-blue-3 btn-link-with-underline ml-1"
                            href="mailto:dpd@desic-sl.com"
                          >
                            dpd@desic-sl.com
                          </a>
                          .
                        </p>
                        <p className="font-size-7 heading-default-color">
                          <u>Sus derechos en protección de datos</u>: toda
                          información en el apartado 4 de esta Política de
                          Privacidad.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          No se realizan decisiones automatizadas ni elaboración
                          de perfiles con sus datos por parte de DESIC.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          6. Calidad y veracidad de los datos.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC) sólo solicitará aquellos datos de carácter
                          personal que resulten adecuados, pertinentes y no
                          excesivos en relación a la finalidad para la que se
                          utilizarán los mismos.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          El Usuario garantiza la veracidad, exactitud, vigencia
                          y autenticidad de los datos de carácter personal
                          proporcionados, respondiendo de los daños y perjuicios
                          que pudiera ocasionar al titular del Sitio Web o a
                          terceros como consecuencia del incumplimiento de esta
                          garantía. Asimismo, se compromete a comunicar a
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC) cualquier modificación o rectificación de los
                          mismos, a fin de que sus se encuentren actualizados en
                          todo momento y no contengan errores. El procedimiento
                          de comunicación de estas circunstancias será el que se
                          establece para el ejercicio de los derechos en
                          protección de datos de carácter personal en el
                          apartado 2 de esta Política de Privacidad.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Quien suministra los datos garantiza y se
                          responsabiliza de ser el titular de los datos que
                          facilita, siendo responsable de cualquier reclamación,
                          daño o perjuicio derivado del incumplimiento de esta
                          circunstancia. Queda expresamente prohibida la
                          suplantación de identidad de cualquier forma, la
                          adopción de identidades falsas, la aportación de datos
                          de carácter personal o información de terceros como
                          propios del Usuario, así como la aportación de
                          cualquier dato de carácter personal no veraz por el
                          Usuario. Queda expresamente prohibida la realización
                          de registros con datos falsos o fraudulentos, pudiendo
                          anularse los mismos, sin perjuicio de la adopción de
                          las medidas legales oportunas.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          7. Datos de terceros.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          No se deberán facilitar datos de carácter personal por
                          quien no resulte ser el titular de los mismos.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Si el Usuario facilitara datos de carácter personal de
                          terceros, con carácter previo, deberá haber informado
                          a dichos terceros de forma expresa, precisa e
                          inequívoca sobre los extremos contenidos en la
                          presente Política de Privacidad y haber obtenido su
                          consentimiento y representación para facilitarlos.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          En caso de incumplimiento por el Usuario de lo
                          dispuesto en el presente apartado, DESARROLLO Y
                          SISTEMAS INFORMÁTICOS CANARIAS, S.L. (DESIC) se exime
                          de cualquier responsabilidad o reclamación de terceros
                          por el tratamiento de los datos personales facilitados
                          por el Usuario con incumplimiento de los requisitos u
                          obligaciones legales por el Usuario, siendo éstos
                          quienes responderán ante DESIC o ante cualquier
                          tercero de todo daño o perjuicio ocasionado y
                          relacionado con estos supuestos.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Y todo ello sin perjuicio de la comunicación a
                          realizar por DESIC a los mencionados terceros, en su
                          caso, conforme a los datos de contacto facilitados por
                          su parte, en cumplimiento de obligación legal.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          8. Datos de menores de edad.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          El acceso y navegación por los contenidos del Sitio
                          Web es libre y permitido a cualquier usuario de
                          Internet. No obstante, la utilización y/o contratación
                          de determinados servicios y/o productos ofrecidos a
                          través del Sitio Web dispone de limitaciones de edad.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          En todo caso, el presente Sitio Web no pretende
                          obtener información ni datos de carácter personal de
                          menores ni directa ni indirectamente.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Para la realización de consultas, el ejercicio de
                          derechos en protección de datos o el establecimiento
                          de contacto informativo en el que se faciliten datos
                          personales, si el Usuario es un menor de edad mayor de
                          14 años, dispone de la capacidad oportuna, y facilita
                          directamente sus datos de carácter personal en el
                          Sitio Web a través de las vías de contacto
                          habilitadas, facilitará sus datos a efectos
                          consultivos, salvo que sea legalmente exigida la
                          asistencia de sus representantes legales. No obstante,
                          si el Usuario es un menor de edad de 14 años o menos,
                          independientemente de que haya podido tener libre
                          acceso a visitar el contenido del Sitio Web, no podrá
                          facilitar directamente sus datos de carácter personal
                          por ninguna de las vías de comunicación o formularios
                          dispuestos en el Sitio Web, requiriéndose en este caso
                          siempre el consentimiento de sus representantes
                          legales.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          La contratación de servicios y el contacto para llevar
                          a cabo los mismos con DESIC, sólo podrá realizarse por
                          Usuarios mayores de edad (18 años) y con capacidad
                          legal suficiente.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC) se exime de cualquier responsabilidad por el
                          incumplimiento de estos requisitos y se reserva el
                          derecho a impedir o no satisfacer las comunicaciones o
                          la contratación de sus productos y/o servicios, por
                          falta de capacidad legal (en protección de datos y
                          conforme a la normativa civil). Para comprobar la edad
                          y capacidad legal y, en su caso, la autenticidad del
                          consentimiento de los representantes legales, DESIC
                          dispondrá de diversos procedimientos, pudiendo
                          solicitar copia del documento de identidad y el
                          contacto con los representantes legales para confirmar
                          la representación y autorización, incluso solicitando
                          la documentación acreditativa de su patria potestad
                          y/o representación legal, sin la cual no podrá
                          tramitar su solicitud.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          En caso de facilitar datos de menores de edad/personas
                          sin capacidad legal mediante la utilización del Sitio
                          Web, quien facilite sus datos y actúe en su
                          representación, declara responsablemente ostentar la
                          representación legal/patria potestad del menor/persona
                          sin capacidad legal, o disponer de autorización
                          expresa de quien/es la ostente/n, por lo que declara
                          disponer de capacidad legal suficiente para
                          proporcionar sus datos y prestar consentimiento en su
                          nombre y representación, pudiendo exigir DESIC la
                          documentación acreditativa de la misma.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          Los representantes legales serán considerados, en
                          cualquier caso, responsables de los actos que realicen
                          las personas que se encuentren a su cargo, conforme a
                          lo dispuesto en la normativa vigente.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          9. Medidas de seguridad.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC) adopta e implanta las medidas de índole
                          técnicas y organizativas necesarias para garantizar la
                          seguridad de los datos personales, conforme a lo
                          exigido por la normativa en protección de datos,
                          evitando su alteración, pérdida, tratamiento o acceso
                          no autorizado y garantizando la confidencialidad,
                          integridad, disponibilidad y resiliencia permanente de
                          los sistemas y servicios de tratamiento.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          No obstante lo anterior, el Usuario debe ser
                          consciente de que las medidas de seguridad en Internet
                          no son, en modo alguno, inquebrantables. DESIC tratará
                          los datos de carácter personal facilitados por el
                          Usuario con la debida confidencialidad y deber de
                          secreto.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          DESIC no será responsable de los tratamientos y
                          posteriores utilizaciones de los datos personales que
                          pudieran efectuarse por terceros prestadores de
                          servicios de la sociedad de la información que actúen
                          fuera del Sitio Web.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">10. Idioma.</h5>
                        <p className="font-size-7 heading-default-color">
                          El idioma de navegación es el español. La presente
                          Política de Privacidad ha sido redactada en español.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          11. Duración y modificación de la Política de
                          Privacidad.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          La Política de Privacidad aplicable será la publicada
                          en el Sitio Web en cada momento.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          DESARROLLO Y SISTEMAS INFORMÁTICOS CANARIAS, S.L.
                          (DESIC) se reserva el derecho a modificar, total o
                          parcialmente, en cualquier momento y sin previo aviso,
                          el texto contenido en el presente documento de
                          Política de Privacidad por ampliación, mejora,
                          concreción o actualización de su contenido, tanto por
                          cambios legislativos o jurisprudenciales o de
                          estrategia empresarial, sin que esta modificación
                          tenga carácter retroactivo. En caso de modificación,
                          dejará de ser aplicable la versión anterior, siendo de
                          aplicación la modificada a partir de la fecha de su
                          publicación.
                        </p>
                        <p className="font-size-7 heading-default-color">
                          La Política de Privacidad estará siempre accesible
                          mediante enlace, con información sobre el número de la
                          versión de que se trata y fecha de publicación, a
                          partir de la cual entra en vigor.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13">
                        <h5 className="font-size-9 mb-7">
                          12. Nulidad y eficacia.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          Si alguna de las cláusulas o textos incluidos en este
                          documento fuera declarada nulo, el propio documento
                          seguirá siendo plenamente eficaz y obligatorio para
                          las partes en los mismos términos, siempre que pueda
                          subsistir sin las cláusulas o textos declarados nulos.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13" id="legit">
                        <h5 className="font-size-9 mb-7">
                          13. Legislación y jurisdicción aplicable.
                        </h5>
                        <p className="font-size-7 heading-default-color">
                          La legislación aplicable será la española. Salvo que
                          resulte de aplicación normativa específica que
                          establezca otra jurisdicción, el Usuario y DESIC, se
                          someten expresamente a los Juzgados y Tribunales de
                          Las Palmas de Gran Canaria para cualquier
                          controversia. Todo ello sin perjuicio de nuestra
                          disposición a la resolución de toda controversia
                          previamente de forma amistosa.
                        </p>
                      </div>
                      <div className="mt-9 mt-lg-13" id="cookies">
                        <h5 className="font-size-9 mb-7">12. Cookies.</h5>
                        <p className="font-size-7 heading-default-color">
                          El Sitio Web utiliza cookies de terceros de Google
                          para protegerse de spam y fraude con el reCAPTCHA. Se
                          aplica la{" "}
                          <a
                            className="text-blue-3 btn-link-with-underline"
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Política de Privacidad
                          </a>{" "}
                          de Google y los{" "}
                          <a
                            className="text-blue-3 btn-link-with-underline"
                            href="https://policies.google.com/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Términos del Servicio
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Privacy;
